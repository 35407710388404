<template>
    <div class="main_container">
        <div class="main_page">
            <div class="page_title">{{ $route.meta.title }}</div>
            <div class="page_containt">
                <div class="page_search">
                    <el-form ref="searchForm" :model="searchForm">
                        <el-row type="flex">
                            <el-col class="search_col">
                                <el-input size="small" type="text" v-model="searchForm.key" style="width: 150px"
                                    placeholder="姓名/手机号" />
                            </el-col>
                            <el-col class="search_col">
                                <el-button type="primary" size="small" native-type="submit"
                                    @click.native.prevent="handleSearch">搜索</el-button>
                            </el-col>
                            <el-col class="search_col">
                                <el-button size="small" @click="resetSearchForm()">重置</el-button>
                            </el-col>
                            <el-col class="btn_col">
                                <el-button type="primary" size="small" @click="doForm(null)">新增员工</el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="page_box" ref="pageBox">
                    <el-table v-loading="loading" row-key="id" size="small" class="el_table" cell-class-name="el_table_cell"
                        header-row-class-name="el_table_header" :data="listData" :height="tableHeight" style="width: 100%">
                        <el-table-column prop="name" label="姓名"> </el-table-column>
                        <el-table-column prop="phone" label="手机号"> </el-table-column>
                        <el-table-column label="状态" width="100">
                            <template slot-scope="scope">
                                <el-select popper-class="select" @change="setStatus(scope.row)" v-model="scope.row.status"
                                    size="small">
                                    <el-option v-for="item in statusOptions" :key="item.status" :label="item.name"
                                        :value="item.status">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="创建日期" prop="created_at" width="140">
                        </el-table-column>
                        <el-table-column fixed="right" align="right" header-align="right" label="操作" width="170">
                            <template slot-scope="scope">
                                <el-button @click="doForm(scope.row)" class="btn" type="text" icon="el-icon-edit"
                                    size="small">
                                    编辑
                                </el-button>
                                <el-button @click="deleteItem(scope.row)" class="btn" type="text" icon="el-icon-delete"
                                    size="small">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="page_pagination">
                        <el-pagination background layout="total,prev,pager,next,jumper"
                            @current-change="handleCurrentChange" :total="pageConfig.counts"
                            :page-size="pageConfig.pageSize" :current-page="pageConfig.pageIndex">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :title="formTitle" :visible.sync="dialogFormVisible" width="400px" :wrapperClosable="false"
            :close-on-press-escape="false">
            <el-form ref="modelForm" :rules="modelRules" :model="modelForm" label-width="100px" size="small">
                <el-form-item label="姓名：" size="small" prop="name">
                    <el-input v-model="modelForm.name" placeholder="姓名" size="small"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" size="small" prop="phone">
                    <el-input v-model="modelForm.phone" placeholder="手机号" size="small"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" style="text-align: center">
                <el-button type="primary" native-type="submit" @click.native.prevent="handleSubmit" :loading="btnLogining"
                    size="small">确 定</el-button>
                <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import staffApi from "@/api/staff";
export default {
    data() {
        return {
            loading: false,
            btnLogining: false,
            dialogFormVisible: false,
            searchForm: {
                key: "",
            },
            statusOptions: [
                {
                    status: 1,
                    name: "开通",
                },
                {
                    status: 0,
                    name: "禁用",
                },
            ],
            formTitle: "",
            model: null,
            modelForm: {
                name: "",
                phone: ""
            },
            modelRules: {
                name: [
                    { required: true, message: "请输入姓名", trigger: "blur" },
                    { min: 1, max: 50, message: "长度不超过50个字符" },
                ],
                phone: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    { min: 1, max: 11, message: "长度不超过11个字符" },
                ],
            },
            listData: [],
            pageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
            tableHeight: 500,
        };
    },
    mounted() {
        onTableHeight(this);
        this.getList(true);
    },
    methods: {
        //查询
        handleSearch() {
            this.getList(true);
        },
        resetSearchForm(formName) {
            this.searchForm = {
                key: "",
            };
            this.getList(true);
        },
        //表单
        doForm(row) {
            this.model = row;
            this.formTitle = this.model == null ? "新增员工" : "编辑员工";
            this.modelForm = {
                name: "",
                phone: ""
            };
            if (this.model != null) {
                this.modelForm.name = this.model.name;
                this.modelForm.phone = this.model.phone;
            }
            this.dialogFormVisible = true;
        },
        //分页
        handleCurrentChange(val) {
            this.getList(true, val);
        },
        //列表
        getList(loading, pageIndex = 1) {
            this.loading = loading;
            staffApi
                .staffList({
                    key: this.searchForm.key,
                    page_size: this.pageConfig.pageSize,
                    page_index: pageIndex,
                })
                .then((res) => {
                    this.loading = false;
                    this.listData = res.list;
                    this.pageConfig.pageIndex = res.page.page_index;
                    this.pageConfig.counts = res.page.counts;
                })
                .catch((res) => {
                    this.loading = false;
                });
        },
        //管理员状态
        setStatus(row) {
            let message = this.$message({
                message: "请求中",
                iconClass: "el-icon-loading",
                customClass: "message-loading",
                duration: 0,
                offset: 60,
            });
            staffApi
                .staffStatus({
                    id: row.id,
                    status: row.status,
                })
                .then((res) => {
                    message.close();
                    this.$message({
                        message: "设置成功",
                        type: "success",
                        offset: 60,
                    });
                    this.getList(false, this.pageConfig.pageIndex);
                })
                .catch((res) => {
                    message.close();
                });
        },
        //删除管理员
        deleteItem(row) {
            this.$confirm("确定是否删除该员工?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let message = this.$message({
                        message: "请求中",
                        iconClass: "el-icon-loading",
                        customClass: "message-loading",
                        duration: 0,
                        offset: 60,
                    });
                    staffApi
                        .staffDelete({
                            id: row.id,
                        })
                        .then((res) => {
                            message.close();
                            this.$message({
                                message: "删除成功",
                                type: "success",
                                offset: 60,
                            });
                            this.getList(true, this.pageConfig.pageIndex);
                        })
                        .catch((res) => {
                            message.close();
                        });
                })
                .catch(() => { });
        },
        handleSubmit() {
            this.$refs.modelForm.validate((valid) => {
                if (valid) {
                    this.btnLogining = true;

                    staffApi
                        .staffForm({
                            id: this.model != null ? this.model.id : 0,
                            name: this.modelForm.name,
                            phone: this.modelForm.phone
                        })
                        .then((res) => {
                            this.btnLogining = false;
                            this.$message({
                                message: "提交成功",
                                type: "success",
                                offset: 60,
                            });
                            this.dialogFormVisible = false;
                            this.getList(true, this.pageConfig.pageIndex);
                        })
                        .catch((res) => {
                            this.btnLogining = false;
                        });
                } else {
                    return false;
                }
            });
        }
    }
}
</script>
<style scope></style>
  