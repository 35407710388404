const requestApi = require("@/lib/request");
// 员工接口
module.exports = {
    //员工列表
    staffList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/staff/staff-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //员工操作
    staffForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/staff/staff-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //员工状态
    staffStatus(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/staff/staff-status', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //删除员工
    staffDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/staff/staff-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    }
}